import { useCallback, useEffect } from 'react';

import useMissionSelect from '#hooks/useMissionSelect';
import useSpacecraftDatabase from '#hooks/useSpacecraftDatabase';

const useCurrentMission = () => {
  const { currentMission } = useMissionSelect();
  const {
    spacecraftDatabase: { missions, fetchedMissionDetails },
    fetchMission,
    fetchSignal,
    fetchEnum,
    fetchStruct,
  } = useSpacecraftDatabase();

  const fetchMissionSignal = useCallback(
    (signalId, isReload) => {
      fetchSignal(currentMission, signalId, isReload);
    },
    [currentMission, fetchSignal],
  );
  const fetchMissionEnum = useCallback(
    (enumId, isReload) => {
      fetchEnum(currentMission, enumId, isReload);
    },
    [currentMission, fetchEnum],
  );
  const fetchMissionStruct = useCallback(
    (structId, isReload) => {
      fetchStruct(currentMission, structId, isReload);
    },
    [currentMission, fetchStruct],
  );

  const missionMeta = missions[currentMission];

  useEffect(() => {
    if (currentMission && !fetchedMissionDetails[currentMission]) {
      fetchMission(currentMission);
    }
  }, [fetchMission, currentMission, fetchedMissionDetails]);

  return {
    missionKey: currentMission,
    missionMeta,
    fetchSignal: fetchMissionSignal,
    fetchEnum: fetchMissionEnum,
    fetchStruct: fetchMissionStruct,
  };
};

export default useCurrentMission;
