// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infrastructure-health-backend-jsx": () => import("./../../../src/pages/infrastructure/health/backend.jsx" /* webpackChunkName: "component---src-pages-infrastructure-health-backend-jsx" */),
  "component---src-pages-infrastructure-health-monitoring-jsx": () => import("./../../../src/pages/infrastructure/health/monitoring.jsx" /* webpackChunkName: "component---src-pages-infrastructure-health-monitoring-jsx" */),
  "component---src-pages-infrastructure-index-jsx": () => import("./../../../src/pages/infrastructure/index.jsx" /* webpackChunkName: "component---src-pages-infrastructure-index-jsx" */),
  "component---src-pages-infrastructure-settings-moc-settings-jsx": () => import("./../../../src/pages/infrastructure/settings/moc-settings.jsx" /* webpackChunkName: "component---src-pages-infrastructure-settings-moc-settings-jsx" */),
  "component---src-pages-infrastructure-settings-soc-settings-jsx": () => import("./../../../src/pages/infrastructure/settings/soc-settings.jsx" /* webpackChunkName: "component---src-pages-infrastructure-settings-soc-settings-jsx" */),
  "component---src-pages-missions-jsx": () => import("./../../../src/pages/missions.jsx" /* webpackChunkName: "component---src-pages-missions-jsx" */),
  "component---src-pages-operations-orbital-elements-catalogs-constants-jsx": () => import("./../../../src/pages/operations/orbital-elements/catalogs.constants.jsx" /* webpackChunkName: "component---src-pages-operations-orbital-elements-catalogs-constants-jsx" */),
  "component---src-pages-operations-orbital-elements-catalogs-jsx": () => import("./../../../src/pages/operations/orbital-elements/catalogs.jsx" /* webpackChunkName: "component---src-pages-operations-orbital-elements-catalogs-jsx" */),
  "component---src-pages-operations-satellite-tracking-tracking-scheduling-jsx": () => import("./../../../src/pages/operations/satellite-tracking/tracking-scheduling.jsx" /* webpackChunkName: "component---src-pages-operations-satellite-tracking-tracking-scheduling-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */)
}

