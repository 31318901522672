import { List, ListSubheader, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import ListItemLink from '#components/ui-helper/ListItemLink';

const NavigationItems = ({ sections, pathPrefix }) => {
  return (
    <List dense style={{ width: '100%' }}>
      {sections?.map(({ title: sectionTitle, items }) => (
        <>
          {/* idx > 0 && <Divider variant="middle" /> */}
          {sectionTitle && (
            <ListSubheader disableSticky>
              <Typography variant="overline">{sectionTitle}</Typography>
            </ListSubheader>
          )}
          {items.map(({ text, path, icon }) => (
            <ListItemLink to={`${pathPrefix ? `${pathPrefix}/` : ''}${path}`} primary={text} icon={icon} />
          ))}
        </>
      ))}
    </List>
  );
};

NavigationItems.propTypes = {
  pathPrefix: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string, path: PropTypes.string })),
    }),
  ).isRequired,
};

export default NavigationItems;
