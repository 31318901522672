import { cloneDeep, defaultsDeep, merge, zipObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer } from 'react';

import SpacecraftDatabaseContext from '#contexts/SpacecraftDatabaseContext';
import useSnackbar from '#hooks/useSnackbar';

import { additionalMissionMeta, getFieldDisplayTypeFromFieldMeta } from '../../data/MissionMeta';

const baseUrl = new URL(`${process.env.GATSBY_MOC_REST_API_URL}/meta/`);
const missionBaseUrl = new URL('missions/', baseUrl);
const signalBaseUrl = new URL('signals/', baseUrl);
const enumBaseUrl = new URL('enum/', baseUrl);

function spacecraftDbEnumsReducer(state, action) {
  if (action.type === 'update/enum') {
    return { ...state, [action.enumId]: action.value };
  }
  return state;
}

function spacecraftDbStructsReducer(state, action) {
  if (action.type === 'update/struct') {
    return { ...state, [action.structId]: action.value };
  }
  if (action.type === 'update/signal') {
    return {
      ...state,
      [action.structId]: {
        ...state[action.structId],
        fields: {
          ...state[action.structId].fields,
          [action.fieldId]: action.value,
        },
      },
    };
  }
  return state;
}

function spacecraftDbMissionsReducer(state, action) {
  switch (action.type) {
    case 'update/missions': {
      const newMissions = {};
      Object.entries(action.value).forEach(([missionName, mission]) => {
        newMissions[missionName] = merge(cloneDeep(state[missionName]) ?? {}, mission);
      });
      return newMissions;
    }
    case 'update/mission': {
      return { ...state, [action.missionName]: action.value };
    }
    case 'update/enum': {
      return {
        ...state,
        [action.missionName]: {
          ...state[action.missionName],
          enums: spacecraftDbEnumsReducer(state[action.missionName]?.enums, action),
        },
      };
    }
    case 'update/struct': {
      return {
        ...state,
        [action.missionName]: {
          ...state[action.missionName],
          structs: spacecraftDbStructsReducer(state[action.missionName]?.structs, action),
        },
      };
    }
    default:
      return state;
  }
}

function spacecraftDbReducer(state, action) {
  switch (action.type) {
    case 'update/mission':
      return {
        ...state,
        fetchedMissionDetails: { ...state.fetchedMissionDetails, [action.missionName]: true },
        missions: spacecraftDbMissionsReducer(state.missions, action),
      };

    default:
      return { ...state, missions: spacecraftDbMissionsReducer(state.missions, action) };
  }
}

// TODO remove this when backend packet generation returns struct metas for subservices
function funcHierarchyToStructMetas(
  funcHierarchyLevels,
  upperLevelEntry,
  currentLevelSelections,
  protocolKey,
  mission,
) {
  const [currentLevelKey, ...innerFunctionHierarchyLevels] = funcHierarchyLevels;
  Object.keys(upperLevelEntry[currentLevelKey]?.ids ?? {}).forEach((id) => {
    if (innerFunctionHierarchyLevels.length === 0) {
      const structId = `__${protocolKey}_${[...currentLevelSelections, id]
        .map((i) => Number(i).toString(16).padStart(2, '0'))
        .join('_')}`;
      // eslint-disable-next-line no-param-reassign
      mission.structs[structId] = upperLevelEntry[currentLevelKey].ids[id];
      // eslint-disable-next-line no-param-reassign
      upperLevelEntry[currentLevelKey].ids[id] = { struct: structId };
    } else {
      funcHierarchyToStructMetas(
        innerFunctionHierarchyLevels,
        upperLevelEntry[currentLevelKey].ids[id],
        [...currentLevelSelections, id],
        protocolKey,
        mission,
      );
    }
  });
}

// TODO remove this when backend packet generation returns struct metas for subservices
function addAdditionalMissionMeta(missionKey, mission) {
  // eslint-disable-next-line no-param-reassign
  mission.structs = {
    ...mission.structs,
    ...Object.fromEntries(Object.entries(mission.protocols ?? {}).map(([pK, p]) => [`__${pK}`, p])),
  };
  // Include additional metadata currently not returned by the spacecraftdb server
  if (Object.keys(additionalMissionMeta).includes(missionKey)) {
    // eslint-disable-next-line no-param-reassign
    defaultsDeep(mission, cloneDeep(additionalMissionMeta[missionKey]));
  }

  // Create struct metas from TCTM subservices
  // eslint-disable-next-line no-param-reassign
  if (mission.protocols !== undefined) {
    Object.keys(mission.protocols)
      .map((k) => `__${k}`)
      .forEach((protocolKey) => {
        const protocolStruct = mission.structs[protocolKey];
        if (protocolStruct.functionHierarchy !== undefined) {
          funcHierarchyToStructMetas(
            protocolStruct.functionHierarchy.map(({ key }) => key),
            protocolStruct,
            [],
            protocolKey,
            mission,
          );
        }
      });
  }
}

async function fetchSignalMeta(missionName, structId, signalId, isReload) {
  const result = await fetch(new URL(signalId, signalBaseUrl), {
    cache: isReload ? 'reload' : 'default',
    mode: 'cors',
  });

  if (result.ok) {
    return result.json();
  }
  throw new Error(`Server returned ${result.status} ${result.statusText} while fetching signal ${signalId}`);
}

async function signalsToFieldMetas(missionName, structId, structMeta) {
  // eslint-disable-next-line no-param-reassign
  structMeta.fields = {
    ...zipObject(
      structMeta.signals.map((signal) => {
        if (typeof signal === 'string') return signal;
        return signal.sid;
      }),
      await Promise.all(
        structMeta.signals?.map(async (signal) => {
          if (typeof signal === 'string' && signal.startsWith('__')) return null;
          const fetched = await fetchSignalMeta(missionName, structId, signal.sid);
          const s = { ...fetched, ...signal };

          // Temporary way to figure out signal type
          // TODO remove this when signals have recorded types
          if (s !== undefined && s.type === undefined) {
            s.type = getFieldDisplayTypeFromFieldMeta(s.enum, s.ctype);
          }

          // Temporary hack for OWL beacon TC forwarding
          if (s.sid === 11536) {
            s.type = 'struct';
            s.ctype = '__TC';
          }

          return s;
        }),
      ),
    ),
    ...structMeta.fields,
  };
  // eslint-disable-next-line no-param-reassign
  delete structMeta.signals;
}

const SpacecraftDatabaseProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [spacecraftDatabase, dispatch] = useReducer(spacecraftDbReducer, {
    missions: {},
    fetchedMissionDetails: {},
  });

  const fetchMissionList = useCallback(
    async (isReload) => {
      try {
        const result = await fetch(missionBaseUrl, { cache: isReload ? 'reload' : 'default' });

        if (result.ok) {
          const missions = Object.fromEntries(
            Object.entries(await result.json()).map(([missionKey, mission]) => {
              addAdditionalMissionMeta(missionKey, mission);
              return [missionKey, mission];
            }),
          );

          dispatch({
            type: 'update/missions',
            value: missions,
          });
        } else {
          throw new Error(`Server returned ${result.status} ${result.statusText}`);
        }
      } catch (e) {
        enqueueSnackbar(
          <span>
            Could not fetch mission list
            <br />
            {e.message}
          </span>,
          { variant: 'error' },
        );
      }
    },
    [enqueueSnackbar],
  );

  const fetchMission = useCallback(
    async (missionName, isReload) => {
      try {
        const result = await fetch(new URL(missionName, missionBaseUrl), { cache: isReload ? 'reload' : 'default' });

        if (result.ok) {
          const mission = await result.json();

          addAdditionalMissionMeta(missionName, mission);

          dispatch({
            type: 'update/mission',
            missionName,
            value: mission,
          });
        } else {
          throw new Error(`Server returned ${result.status} ${result.statusText}`);
        }
      } catch (e) {
        enqueueSnackbar(
          <span>
            Could not fetch metadata for mission &lsquo;{missionName}&rsquo;
            <br />
            {e.message}
          </span>,
          { variant: 'error' },
        );
      }
    },
    [enqueueSnackbar],
  );

  const fetchEnum = useCallback(
    async (missionName, enumId, isReload) => {
      if (enumId.toString().startsWith('__')) return; // Do not fetch temp handwritten meta
      try {
        const result = await fetch(new URL(`mission/${missionName}/${enumId}`, enumBaseUrl), {
          cache: isReload ? 'reload' : 'default',
          mode: 'cors',
        });

        if (result.ok) {
          dispatch({
            type: 'update/enum',
            missionName,
            enumId,
            value: await result.json(),
          });
        } else {
          throw new Error(`Server returned ${result.status} ${result.statusText}`);
        }
      } catch (e) {
        enqueueSnackbar(
          <span>
            Could not fetch metadata for enum &lsquo;{enumId}&rsquo;
            <br />
            {e.message}
          </span>,
          { variant: 'error' },
        );
      }
    },
    [enqueueSnackbar],
  );

  const fetchStruct = useCallback(
    async (missionName, structId, isReload) => {
      try {
        let struct;
        if (structId.toString().startsWith('__')) {
          struct = spacecraftDatabase.missions[missionName]?.structs?.[structId];
        } else {
          const result = await fetch(new URL(`${missionName}/structs/${structId}`, missionBaseUrl), {
            cache: isReload ? 'reload' : 'default',
            mode: 'cors',
          });

          if (result.ok) {
            struct = await result.json();
          } else {
            throw new Error(`Server returned ${result.status} ${result.statusText}`);
          }
        }

        if (!struct) return;

        await signalsToFieldMetas(missionName, structId, struct);

        dispatch({
          type: 'update/struct',
          missionName,
          structId,
          value: struct,
        });
      } catch (e) {
        enqueueSnackbar(
          <span>
            Could not fetch metadata for struct &lsquo;{structId}&rsquo;
            <br />
            {e.message}
          </span>,
          { variant: 'error' },
        );
      }
    },
    [enqueueSnackbar, spacecraftDatabase],
  );

  useEffect(() => {
    fetchMissionList(false);
  }, [fetchMissionList]);

  return (
    <SpacecraftDatabaseContext.Provider
      value={{
        spacecraftDatabase,
        isLoaded: Object.keys(spacecraftDatabase.missions).length > 0,
        fetchMissionList,
        fetchMission,
        fetchStruct,
        fetchEnum,
      }}
    >
      {children}
    </SpacecraftDatabaseContext.Provider>
  );
};

SpacecraftDatabaseProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default SpacecraftDatabaseProvider;
