import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMatch } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';

import NavigationItems from './NavigationItems';

/*
function BoxHeaderLink(props) {
  const { icon, title, to } = props;

  const renderLink = useMemo(() => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />), [
    to,
  ]);

  return (
    <AccordionSummary component={renderLink} expandIcon={<ExpandMoreIcon />}>
      {icon}
      <Typography variant="button">{title}</Typography>
    </AccordionSummary>
  );
} */

const NavigationBox = ({ title, pathPrefix, icon, sections, children, ...otherMuiAccordionProps }) => {
  const match = useMatch(`${pathPrefix}/*`);

  return (
    <Accordion defaultExpanded={match !== null} {...otherMuiAccordionProps}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {icon}
        <Typography variant="button">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
        {children ?? <NavigationItems pathPrefix={pathPrefix} sections={sections} />}
      </AccordionDetails>
    </Accordion>
  );
};

NavigationBox.propTypes = {
  title: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
  icon: PropTypes.element,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string, path: PropTypes.string })),
    }),
  ),
  children: PropTypes.arrayOf(PropTypes.element),
};

NavigationBox.defaultProps = {
  icon: undefined,
  sections: undefined,
  children: undefined,
};

export default NavigationBox;
