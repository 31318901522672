import { Grid, Paper } from '@material-ui/core';
import { Match, Router } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';

import Wordmark from './icons/Wordmark';
import { MissionNavigation, NavigationMissionBox } from './mission-navigation';
import NavigationBox from './NavigationBox';
import NavigationItems from './NavigationItems';
import NavigationUserBox from './NavigationUserBox';
import { useStyles } from './styles';

const homeNav = [
  {
    items: [{ text: 'Dashboard', path: '/' }],
  },
];

const operationsNav = [
  {
    title: 'Orbital Elements',
    items: [{ text: 'Catalogs', path: 'orbital-elements/catalogs' }],
  },
  {
    title: 'Satellite Tracking',
    items: [{ text: 'Tracking Scheduling', path: 'satellite-tracking/tracking-scheduling' }],
  },
];

const infrastructureNav = [
  {
    title: 'Health',
    items: [
      { text: 'Backend Status', path: 'health/backend' },
      { text: 'Monitoring', path: 'health/monitoring' },
    ],
  },
  {
    title: 'Logs',
    items: [
      { text: 'Audit Log', path: 'logs/audit-log' },
      { text: 'System Log', path: 'logs/system-log' },
    ],
  },
  {
    title: 'Settings',
    items: [
      { text: 'MOC Settings', path: 'settings/moc-settings' },
      { text: 'SOC Settings', path: 'settings/soc-settings' },
    ],
  },
  {
    title: 'Site',
    items: [
      { text: 'Help', path: 'site/help' },
      { text: 'About C3S MOC', path: 'site/about' },
    ],
  },
];

const HomeNavigation = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.navGroup}>
        <Paper>
          <NavigationItems sections={homeNav} />
        </Paper>
      </div>
      <div className={classes.navGroup}>
        <NavigationBox title="Operations" pathPrefix="/operations" sections={operationsNav} />
      </div>
      <div className={classes.navGroup}>
        <NavigationMissionBox />
      </div>
      <div className={classes.navGroup}>
        <NavigationBox title="Infrastructure" pathPrefix="/infrastructure" sections={infrastructureNav} />
      </div>
    </>
  );
};

const Navigation = () => {
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      style={{ flexWrap: 'nowrap', minHeight: '100%', maxWidth: '100%' }}
    >
      <Grid item>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Link to="/">
              <Wordmark />
            </Link>
          </Grid>
          <Match path="/missions/:currentMission/*">
            {({ match }) =>
              match && (
                <Grid item>
                  <Link to={`/missions/${match.currentMission}/`}>{match.currentMission.toUpperCase()}</Link>
                </Grid>
              )
            }
          </Match>
        </Grid>
        <Router primary={false}>
          <HomeNavigation default />
          <MissionNavigation path="/missions/:currentMission/*" />
        </Router>
      </Grid>
      <Grid item>
        <NavigationUserBox />
      </Grid>
    </Grid>
  );
};

export default Navigation;
