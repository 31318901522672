import { blue, grey, orange } from '@material-ui/core/colors';

export const bigFontSize = '1em';
export const normalFontSize = '0.92em';
export const smallFontSize = '0.8em';

const styles = (theme) => {
  return {
    ButtonLabel: {
      '&:disabled': {
        color: 'black',
      },
    },
    ButtonMaxWidth50: {
      maxWidth: '50px',
    },
    Card: {
      borderRadius: 'unset',
      boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
      margin: [0, `${theme.spacing(2)}px`, `${theme.spacing(1)}px`, 0].join(' '),

      '&:last-child': {
        marginRight: 'unset',
      },
    },
    CardHeader: {
      padding: theme.spacing(2),
    },
    CardContent: {
      display: 'flex',
      padding: 'unset',
      paddingLeft: theme.spacing(1),
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
      '&>*': {
        flex: 1,
        margin: [0, `${theme.spacing(1)}px`, `${theme.spacing(1)}px`, 0].join(' '),
        '&:first-child': {
          marginLeft: theme.spacing(2),
        },
        '&:last-child': {
          marginRight: theme.spacing(2),
        },
      },
    },
    MiniCard: {
      borderRadius: 4,
      boxShadow: 'unset !important',
    },
    MiniCardHeader: {
      padding: 0,
    },
    MiniCardContent: {
      padding: '0 0.7em 0 0',
      fontSize: '0.9rem',
      '&:last-child': {
        paddingBottom: 'unset',
      },
    },
    Checkbox: {
      padding: 'unset',
    },
    DialogContent: {
      padding: 'unset !important',
      overflowY: 'unset',
    },
    DialogCard: {
      boxShadow: 'unset',
      padding: theme.spacing(2),
    },
    Accordion: {
      boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
      '&:before': {
        backgroundColor: 'unset',
      },
      marginTop: `${theme.spacing(2)}px !important`,
    },
    errorColor: {
      color: '#fd397a',
    },
    undefColor: {
      color: grey[500],
    },
    fontAwesomeIconHolder: {
      lineHeight: 0,
    },
    FormControlLabel: {
      flex: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '& span:last-child': {
        fontSize: normalFontSize,
      },
    },
    infoColor: {
      color: '#5578eb',
    },
    ListItemText: {
      fontSize: smallFontSize,
    },
    Link: {
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
      },
      '&:focus': {
        outline: 'unset',
      },
    },
    ModifierAutoMargin: {
      margin: 'auto',
    },
    StatusPanelLink: {
      color: blue[500],
      '&:focus': {
        outline: 'unset',
      },
      '&:hover': {
        color: blue[500],
      },
    },
    successColor: {
      color: '#0abb87',
    },
    table: {
      width: '100%',
      '& > thead > tr > th': {
        padding: '4px 8px',
      },
      '& > tbody > tr > td': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '4px 8px',
        '&:last-child': {
          width: '100%',
        },
      },
      '& > tbody > tr': {
        '&:nth-child(even):hover': {
          backgroundColor: grey['200'],
        },
        '&:nth-child(odd):hover': {
          backgroundColor: grey['200'],
        },
        '&:last-child': {
          backgroundColor: 'white',
        },
      },
    },
    warningColor: {
      color: orange[500],
    },
    Wrapper: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  };
};

export default styles;
