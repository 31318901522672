import { makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import RealTimeBackendCard from '#components/page-content/backend-page/RealTimeBackendCard';
import SpacecraftDatabaseStatusCard from '#components/page-content/spacecraft-database/SpacecraftDatabaseStatusCard';
import Wordmark from '#components/page-fragments/navigation/icons/Wordmark';

const loadingFun = [
  'Any moment now',
  'Playing elevator music',
  'Opening pod bay doors',
  'Acquiring dilithium',
  'Accerelating to 88mph',
  'Doing Kessel Run in 12 parsecs',
  'Finding towel',
  'Treating Kessler syndrome',
  'Calling orbital mechanic',
  'Adding more boosters',
  'Adding more struts',
  'Reticulating splines',
];

function randomLoadingFun() {
  return `${loadingFun[Math.floor(Math.random() * loadingFun.length)]}...`;
}

const useStyles = makeStyles((theme) => ({
  logo: {
    filter: 'brightness(0.5)',
  },
  pageStatusContainer: {
    width: '25vw',
    minWidth: '20em',
    minHeight: '70vh',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: theme.spacing(2),
  },
  pageStatusProgress: {},
  pageStatusText: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

const PageStatus = ({ isAuthenticated, isDsLoading, isSpacecraftDbLoaded }) => {
  const classes = useStyles();

  const [disturbance, setDisturbance] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => setDisturbance(true), 5000);
    return () => clearTimeout(t);
  }, []);

  let loadingText;
  if (!isAuthenticated) {
    loadingText = 'Authenticating...';
  } else if (isDsLoading) {
    loadingText = 'Connecting to realtime backend...';
  } else if (!isSpacecraftDbLoaded) {
    loadingText = 'Loading mission metadata...';
  } else {
    loadingText = randomLoadingFun();
  }

  return (
    <div className={classes.pageStatusContainer}>
      <Wordmark height="80px" className={classes.logo} />
      <LinearProgress
        value={0}
        valueBuffer={0}
        variant={
          /* eslint-disable-next-line no-nested-ternary */
          disturbance ? 'buffer' : isAuthenticated ? 'indeterminate' : 'query'
        }
        className={classes.pageStatusProgress}
      />
      <Typography variant="body1" className={classes.pageStatusText}>
        {disturbance ? 'Disturbance in the Force' : loadingText}
      </Typography>
      {disturbance && <RealTimeBackendCard />}
      {disturbance && isAuthenticated && <SpacecraftDatabaseStatusCard isLoaded={isSpacecraftDbLoaded} />}
    </div>
  );
};

PageStatus.propTypes = {
  isAuthenticated: PropTypes.bool,
  isDsLoading: PropTypes.bool,
  isSpacecraftDbLoaded: PropTypes.bool,
};
PageStatus.defaultProps = {
  isAuthenticated: false,
  isDsLoading: false,
  isSpacecraftDbLoaded: false,
};

export default PageStatus;
