import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import styles from '#utils/styles';

import { ColoredCircleIcon } from '../../ui-helper';

const useStyles = makeStyles(styles);

const dsConnectionStates = new Map([
  ['OPEN', { status: 'success', text: 'Connected' }],
  ['RECONNECTING', { status: 'warning', text: 'Reconnecting' }],
  ['CLOSED', { status: 'danger', text: 'Disconnected' }],
  ['ERROR', { status: 'danger', text: 'Unable to connect' }],
  ['OFFLINE', { status: 'warning', text: 'Disconnected deliberately' }],
  ['AWAITING_CONNECTION', { status: 'info', text: 'Awaiting connection' }],
  ['CHALLENGING', { status: 'info', text: 'Challenging' }],
  ['AWAITING_AUTHENTICATION', { status: 'info', text: 'Awaiting authentication' }],
  ['AUTHENTICATING', { status: 'info', text: 'Authenticating' }],
]);

const RealTimeBackendCard = () => {
  const classes = useStyles();
  const { isDsLoading, isDsAuthenticated, dsConnectionState, dsError, clientData } = useContext(DeepstreamContext);

  const dsConnection = dsConnectionStates.get(dsConnectionState);

  return (
    <Card className={classes.Card}>
      <CardHeader title="Real-Time Backend" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
      <CardContent className={classes.CardContent}>
        <Table style={{ width: '100%' }} size="small">
          <TableBody>
            <TableRow>
              <TableCell style={{ width: '300px' }}>
                <b>Connection state</b>
              </TableCell>
              <TableCell>
                <ColoredCircleIcon status={dsConnection?.status ?? 'warning'} />{' '}
                {`${dsConnection?.text ?? dsConnectionState ?? '-'}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Background operation in progress</b>
              </TableCell>
              <TableCell>{`${isDsLoading}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Is connection authenticated</b>
              </TableCell>
              <TableCell>{`${isDsAuthenticated}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Connection error</b>
              </TableCell>
              <TableCell>{`${dsError ?? '-'}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Role</b>
              </TableCell>
              <TableCell>{`${clientData?.role ?? '-'}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Auth0 Roles</b>
              </TableCell>
              <TableCell>{`${clientData?.authRoles?.join(',') ?? '-'}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Username</b>
              </TableCell>
              <TableCell>{`${clientData?.userName ?? '-'}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Firstname</b>
              </TableCell>
              <TableCell>{`${clientData?.firstName ?? '-'}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Lastname</b>
              </TableCell>
              <TableCell>{`${clientData?.lastName ?? '-'}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Displayname</b>
              </TableCell>
              <TableCell>{`${clientData?.displayName ?? '-'}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>{`${clientData?.email ?? '-'}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default RealTimeBackendCard;
