import PropTypes from 'prop-types';
import React from 'react';

import TrackingContext from '#contexts/TrackingContext';

const trackingStations = {
  'lurdy-ts': {
    name: 'C3S Lurdy Station',
  },
  'elte-ts': {
    name: 'ELTE Station',
  },
};

const TrackingContextProvider = ({ children }) => {
  return <TrackingContext.Provider value={{ trackingStations }}>{children}</TrackingContext.Provider>;
};

TrackingContextProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default TrackingContextProvider;
