import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { DeepstreamClient } from '@deepstream/client';
import { CONNECTION_STATE, EVENT } from '@deepstream/client/dist/src/constants';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';

import PageStatus from '#components/PageStatus/PageStatus';
import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

const credentials = {
  role: 'user',
  credentials: {
    username: 'anonymous',
    password: '********',
  },
};

const DeepstreamProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, getAccessTokenSilently /* user */ } = useAuth0();
  const [, setCookie] = useCookies(['auth0.accessToken']);

  const dsClient = useRef();
  const [dsConnectionState, setDsConnectionState] = useState(CONNECTION_STATE.CLOSED);
  const [clientData, setClientData] = useState({});

  const dsReconnect = useCallback(() => {
    dsClient.current?.close();

    if (isAuthenticated) {
      const client = new DeepstreamClient(process.env.GATSBY_DEEPSTREAM_URL, {
        reconnectIntervalIncrement: 500,
        maxReconnectAttempts: Infinity,
      });
      client.on(EVENT.CONNECTION_STATE_CHANGED, setDsConnectionState);
      dsClient.current = client;
    }
  }, [isAuthenticated]);

  const dsLogin = useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
      scope: process.env.GATSBY_AUTH0_SCOPE,
    });

    setCookie('auth0.accessToken', accessToken, {
      domain: process.env.GATSBY_COOKIE_DOMAIN,
      path: '/' /* , httpOnly: true  */,
      secure: process.env.GATSBY_COOKIE_DOMAIN !== 'localhost',
      sameSite: process.env.GATSBY_COOKIE_DOMAIN !== 'localhost' ? 'none' : false,
    });

    dsClient.current.login({ ...credentials, accessToken }, (success, data) => {
      if (success) {
        setClientData(data);
      }
    });
  }, [getAccessTokenSilently, setCookie]);

  useEffect(() => {
    switch (dsConnectionState) {
      case CONNECTION_STATE.CLOSED:
      case CONNECTION_STATE.ERROR:
        dsReconnect();
        break;
      case CONNECTION_STATE.AWAITING_AUTHENTICATION:
        dsLogin();
        break;
      default:
        if (!isAuthenticated) {
          dsClient.current?.close();
        }
        break;
    }
  }, [dsConnectionState, dsLogin, dsReconnect, isAuthenticated]);

  useEffect(() => {
    return () => {
      dsClient.current?.close();
    };
  }, []);

  const isDsLoading = !(
    dsConnectionState === CONNECTION_STATE.OPEN ||
    dsConnectionState === CONNECTION_STATE.CLOSED ||
    dsConnectionState === CONNECTION_STATE.ERROR ||
    dsConnectionState === CONNECTION_STATE.OFFLINE
  );
  const isDsAuthenticated = isAuthenticated && dsConnectionState === CONNECTION_STATE.OPEN;

  const rpcWithErrorHandler = useCallback(
    async (rpcName, params) => {
      try {
        const result = await dsClient.current.rpc.make(rpcName, params);
        return { status: 'success', result };
      } catch (err) {
        enqueueSnackbar(
          <span>
            {err}
            <br />
            {rpcName}
          </span>,
          { variant: 'error' },
        );
        return { status: 'error', result: err };
      }
    },
    [enqueueSnackbar],
  );

  return (
    <DeepstreamContext.Provider
      value={{
        isDsLoading,
        isDsAuthenticated,
        clientData,
        dsClient: dsClient.current ?? { rpc: null, event: null, record: null },
        dsConnectionState,
        rpcWithErrorHandler,
      }}
    >
      {children}
    </DeepstreamContext.Provider>
  );
};

DeepstreamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withAuthenticationRequired(DeepstreamProvider, {
  onRedirecting: () => <PageStatus isAuthenticated={false} />,
});
