import { useEffect } from 'react';

import useCurrentMission from '#hooks/useCurrentMission';

import { structNameAliases } from '../data/MissionMeta';

const useCurrentMissionStructMeta = (structId) => {
  const { missionKey, missionMeta, fetchStruct } = useCurrentMission();

  // Temporary workaround for making handwritten struct meta overrides work
  // TODO remove this when packet meta handling has been sorted out
  if (
    missionMeta?.structs !== undefined &&
    Object.prototype.hasOwnProperty.call(missionMeta?.structs, `__${structId}`)
  ) {
    // eslint-disable-next-line no-param-reassign
    structId = `__${structId}`;
  }

  // Workaround to make the ADCS inner packet hierarchies work for VIREO
  // TODO remove this when packet meta handling has been sorted out
  // eslint-disable-next-line no-param-reassign
  structId = structNameAliases[missionKey][structId] ?? structId;

  const structMeta = missionMeta?.structs?.[structId];

  useEffect(() => {
    if (structMeta === undefined) {
      if (typeof structId !== 'undefined' && structId !== null && structId !== '') {
        fetchStruct(structId);
      }
    }
  }, [structMeta, fetchStruct, structId]);

  useEffect(() => {
    if (structId?.startsWith('__') && structMeta?.signals !== undefined) {
      fetchStruct(structId);
    }
  }, [structMeta, fetchStruct, structId]);

  return structMeta;
};

export default useCurrentMissionStructMeta;
