import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar as useSnackbarOriginal } from 'notistack';
import React, { useCallback } from 'react';

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbarOriginal();

  const action = useCallback(
    (key) => (
      <IconButton onClick={() => closeSnackbar(key)} style={{ color: 'white' }}>
        <FontAwesomeIcon icon={['fas', 'window-close']} size="xs" />
      </IconButton>
    ),
    [closeSnackbar],
  );

  return {
    enqueueSnackbar: useCallback((message, options) => enqueueSnackbar(message, { action, ...options }), [
      action,
      enqueueSnackbar,
    ]),
    closeSnackbar,
  };
};

export default useSnackbar;
