import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, IconButton, List, Paper } from '@material-ui/core';
import React from 'react';

import ListItemLink from '#components/ui-helper/ListItemLink';

const NavigationUserBox = () => {
  const { user, logout } = useAuth0();

  return (
    <Paper>
      <List dense>
        <ListItemLink
          to="/profile"
          avatar={<Avatar alt={user.name} src={user.picture} />}
          secondaryAction={
            <IconButton edge="end" onClick={logout}>
              <FontAwesomeIcon icon={['fas', 'sign-out-alt']} size="sm" />
            </IconButton>
          }
        />
      </List>
    </Paper>
  );
};

export default NavigationUserBox;
