import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import TestingContext from '#contexts/TestingContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useSnackbar from '#hooks/useSnackbar';

const TestingContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    rpcWithErrorHandler,
    clientData: { userName },
  } = useContext(DeepstreamContext);
  const [testConnectors, setTestConnectors] = useState({});

  const { missionKey } = useCurrentMission();
  const testConnectorCRUDPath = `rg/${missionKey}/@c3s/debug-connector-crud`;
  const testerProviderBasePath = `rg/${missionKey}/@c3s/tester-provider`;

  const listTestConnectors = useCallback(async () => {
    const { status, result } = await rpcWithErrorHandler(`${testConnectorCRUDPath}/list`, {});
    if (status === 'success') {
      setTestConnectors(result);
    }
  }, [rpcWithErrorHandler, testConnectorCRUDPath]);

  const addTestConnector = useCallback(
    async (id, connector) => {
      const { status } = await rpcWithErrorHandler(`${testConnectorCRUDPath}/create`, { [id]: connector });
      if (status === 'success') {
        enqueueSnackbar('Added new test connector successfully', { variant: 'info' });
        await listTestConnectors();
      }
    },
    [rpcWithErrorHandler, testConnectorCRUDPath, enqueueSnackbar, listTestConnectors],
  );

  const removeTestConnector = useCallback(
    async (id) => {
      const { status } = await rpcWithErrorHandler(`${testConnectorCRUDPath}/remove`, { id });
      if (status === 'success') {
        enqueueSnackbar('Deleted test connector successfully', { variant: 'info' });
        await listTestConnectors();
      }
    },
    [rpcWithErrorHandler, testConnectorCRUDPath, enqueueSnackbar, listTestConnectors],
  );

  const updateTestConnector = useCallback(
    async (id, connector) => {
      const { status } = await rpcWithErrorHandler(`${testConnectorCRUDPath}/update`, {
        [id]: connector,
      });
      if (status === 'success') {
        enqueueSnackbar('Updated test connector successfully', { variant: 'info' });
        await listTestConnectors();
      }
    },
    [rpcWithErrorHandler, testConnectorCRUDPath, enqueueSnackbar, listTestConnectors],
  );

  const connectToTestConnector = useCallback(
    async (id) => {
      const { status } = await rpcWithErrorHandler(`${testerProviderBasePath}/${id}/connect`, { userName });
      if (status === 'success') {
        enqueueSnackbar('Connected.', { variant: 'success' });
      }
    },
    [rpcWithErrorHandler, testerProviderBasePath, userName, enqueueSnackbar],
  );

  const disconnectFromTestConnector = useCallback(
    async (id) => {
      const { status } = await rpcWithErrorHandler(`${testerProviderBasePath}/${id}/disconnect`, { userName });
      if (status === 'success') {
        enqueueSnackbar('Disconnected.', { variant: 'info' });
      }
    },
    [rpcWithErrorHandler, testerProviderBasePath, userName, enqueueSnackbar],
  );

  useEffect(() => {
    if (missionKey !== null) {
      listTestConnectors();
    }
  }, [listTestConnectors, missionKey]);

  return (
    <TestingContext.Provider
      value={{
        testConnectors,
        listTestConnectors,
        addTestConnector,
        updateTestConnector,
        removeTestConnector,
        connectToTestConnector,
        disconnectFromTestConnector,
      }}
    >
      {children}
    </TestingContext.Provider>
  );
};

TestingContextProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default TestingContextProvider;
