import 'typeface-roboto';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';

import DeepstreamProvider from '#services/authentication/DeepstreamProvider';
import SpacecraftDatabaseProvider from '#services/spacecraft-database/SpacecraftDatabaseProvider';

import favicon16 from '../assets/images/favicon_16x16.png';
import favicon32 from '../assets/images/favicon_32x32.png';
import theme from '../themes/c3s-moc';
import PageProtection from './PageProtection';

library.add(fas);
library.add(far);

const snackbarOptions = {
  vertical: 'bottom',
  horizontal: 'right',
};

const Layout = ({ children }) => {
  return (
    <>
      <Helmet defaultTitle="C3S Mission Operation Center">
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="description" content="Satellite mission operation software." />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
        <link rel="icon" type="image/png" href={`${favicon16}`} sizes="16x16" />
        <link rel="icon" type="image/png" href={`${favicon32}`} sizes="32x32" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3} anchorOrigin={snackbarOptions}>
          <CookiesProvider>
            <DeepstreamProvider>
              <SpacecraftDatabaseProvider>
                <PageProtection>{children}</PageProtection>
              </SpacecraftDatabaseProvider>
            </DeepstreamProvider>
          </CookiesProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
  }).isRequired,
};

export default Layout;
