import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import useSpacecraftDatabase from '#hooks/useSpacecraftDatabase';

import PageStatus from './PageStatus/PageStatus';
import ProtectedLayout from './ProtectedLayout';

const PageProtection = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { dsConnectionState, isDsLoading, isDsAuthenticated } = useContext(DeepstreamContext);
  const { isLoaded: isSpacecraftDbLoaded } = useSpacecraftDatabase();

  useEffect(() => {
    switch (dsConnectionState) {
      case 'CLOSED':
        if (isDsAuthenticated) {
          enqueueSnackbar('Disconnected from backend.', { variant: 'info' });
        }
        break;
      case 'ERROR':
        enqueueSnackbar('Unable to connect to backend.', { variant: 'error' });
        break;
      case 'RECONNECTING':
        enqueueSnackbar('Reconnecting to backend.', { variant: 'warning' });
        break;
      default:
        break;
    }
  }, [dsConnectionState, isDsAuthenticated, enqueueSnackbar]);

  if (dsConnectionState !== 'OPEN' || !isDsAuthenticated || !isSpacecraftDbLoaded) {
    return <PageStatus isAuthenticated isDsLoading={isDsLoading} isSpacecraftDbLoaded={isSpacecraftDbLoaded} />;
  }

  return <ProtectedLayout>{children}</ProtectedLayout>;
};

PageProtection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageProtection;
