import { useMatch } from '@reach/router';
import { navigate } from 'gatsby';
import { useCallback } from 'react';

import useSpacecraftDatabase from '#hooks/useSpacecraftDatabase';

const pathPrefix = '/missions';

export default function useMissionSelect() {
  const {
    spacecraftDatabase: { missions },
  } = useSpacecraftDatabase();
  const missionOptions = Object.entries(missions).map(([missionKey, { displayName: missionName }]) => ({
    value: missionKey,
    label: missionName,
  }));

  const match = useMatch(`${pathPrefix}/:currentMission/*`);
  const currentMission = Object.keys(missions).includes(match?.currentMission) ? match.currentMission : null;

  const selectMission = useCallback(
    (missionValue) => {
      if (match !== null) {
        navigate(`${pathPrefix}/${missionValue}/${match['*']}`);
      } else {
        navigate(`${pathPrefix}/${missionValue}/`);
      }
    },
    [match],
  );

  return { missionOptions, currentMission, selectMission };
}
