import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blue, green, grey, purple, red, yellow } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React from 'react';

const statusColors = new Map([
  ['primary', grey[500]],
  ['secondary', blue[500]],
  ['success', green[500]],
  ['danger', red[500]],
  ['warning', yellow[500]],
  ['info', purple[500]],
]);

export function statusColor(status) {
  return statusColors.get(status) || grey[500];
}

const httpStatusCodeColors = new Map([
  [1 /* 1xx */, 'info'],
  [2 /* 2xx */, 'success'],
  [3 /* 3xx */, 'primary'],
  [4 /* 4xx */, 'danger'],
  [5 /* 5xx */, 'danger'],
]);

export function httpStatusCodeColor(code) {
  return httpStatusCodeColors.get(Math.trunc(code / 100)) || 'secondary';
}

export const ColoredCircleIcon = ({ status }) => (
  <FontAwesomeIcon icon={['fas', 'circle']} style={{ color: statusColor(status) }} />
);

ColoredCircleIcon.propTypes = {
  status: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'default']).isRequired,
};
