import { defaultsDeep } from 'lodash';

const radcubeClassMissionsAdditionalMeta = {
  commandingProtocol: 'TCFrame',
  structs: {
    __ETH: {
      displayName: 'ETH Packet',
    },
    __SSP: {
      displayName: 'SSP Packet',
    },
    __TCFrame: {
      displayName: 'TC Frame',
      signals: [{ sid: 1 }, { sid: 2 }, { sid: 3 }],
      fields: {
        __TCFrame_payload: {
          sid: '__TCFrame_payload',
          displayName: 'TC Packet',
          ctype: '__TC',
          type: 'struct',
          name: 'Packet',
        },
      },
    },
    __TC: {
      displayName: 'TC Packet',
      signals: [{ sid: 8 }, { sid: 7 }, { sid: 10 }, { sid: 14 }, { sid: 9 }, { sid: 11 }, { sid: 12 }],
      functionHierarchy: [
        { key: 'applicationProcesses', field: 9 },
        { key: 'services', field: 11 },
        { key: 'subservices', field: 12 },
      ],
      payloadId: '__TCPacket_payload',
      fields: {
        __TCPacket_payload: {
          sid: '__TCPacket_payload',
          displayName: 'Data',
          name: 'Data',
          type: 'struct',
        },
      },
    },
  },
};

const vireoAdditionalMeta = defaultsDeep(
  {
    structs: {
      __ADCS_08_01: {
        signals: [{ sid: 10590 }],
        functionHierarchy: [{ key: 'parameterIDs', field: 10590 }],
        payloadId: '__parameter_array',
        fields: { __parameter_array: { displayName: 'Parameter Array', name: 'Parameter_Array', type: 'struct' } },
      },
      __ADCS_09_01: {
        signals: [{ sid: 10695 }],
        functionHierarchy: [{ key: 'parameterIDs', field: 10695 }],
        payloadId: '__parameter_array',
        fields: { __parameter_array: { displayName: 'Parameter Array', name: 'Parameter_Array', type: 'struct' } },
      },
    },
  },
  radcubeClassMissionsAdditionalMeta,
);

const owlAdditionalMeta = {
  commandingProtocol: 'TC',
  structs: {
    __PC2GS: {
      displayName: 'PC2GS Packet',
      functionHierarchy: [
        { key: 'services', field: 'Service' },
        { key: 'subservices', field: 'SubService' },
      ],
      payloadId: '__PC2GSPacket_payload',
      fields: {
        Service: {
          displayName: 'Service',
          name: 'Service',
          type: 'enum',
        },
        SubService: {
          displayName: 'Subservice',
          name: 'SubService',
          type: 'enum',
        },
        __PC2GSPacket_payload: {
          displayName: 'Packet Data',
          name: 'Data',
          type: 'struct',
        },
      },
    },
    __TC: {
      displayName: 'TC Packet',
      signals: [{ sid: 11378 }, { sid: 11201 }, { sid: 11090 }, { sid: 11390 }, { sid: 11091 }, { sid: 11092 }],
      functionHierarchy: [
        { key: 'services', field: 11090 },
        { key: 'subservices', field: 11091 },
      ],
      payloadId: '__TCPacket_payload',
      fields: {
        __TCPacket_payload: {
          displayName: 'Data',
          name: 'Data',
          type: 'struct',
        },
      },
    },
  },
};

export function getFieldDisplayTypeFromFieldMeta(enumId, ctype) {
  if (enumId === 'bool') {
    return 'boolean';
  }
  if (enumId !== '' && enumId !== null) {
    return 'enum';
  }
  if (ctype?.match(/^u?int(8|16|32|64)_t$/) || ctype === 'float' || ctype === 'double') {
    return 'number';
  }
  if (ctype?.match(/uint8_t\s*\[[0-9]+\]/)) {
    return 'bytes';
  }
  if (ctype?.match(/(char|char8_t)\s*\[[0-9]+\]/)) {
    return 'string';
  }
  if (ctype?.match(/\w+\s*\[[0-9]+\]/)) {
    return 'array';
  }
  if (ctype?.endsWith('_t')) {
    return 'struct';
  }
  return 'complex';
}

export const additionalMissionMeta = {
  radcube: radcubeClassMissionsAdditionalMeta,
  vireo: vireoAdditionalMeta,
  owl: owlAdditionalMeta,
};

const vireoStructNameAliases = {
  ____TC_05_08_01: '__ADCS_08_01',
  ____TC_05_09_01: '__ADCS_09_01',
};

export const structNameAliases = {
  radcube: {},
  vireo: vireoStructNameAliases,
  owl: {},
};
