import { grey, yellow } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f5f5f5',
    },
    grey: {
      main: grey[500],
    },
    yellow: {
      main: yellow[500],
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 0,
        '&:first-child': {
          paddingLeft: '2em',
        },
        '&:last-child': {
          paddingRight: '2em',
        },
      },
    },
    MuiTypography: {
      h6: {
        fontSize: '1rem',
        textTransform: 'unset',
        color: 'inherit',
      },
      caption: {
        fontSize: '0.65rem',
        lineHeight: 1,
      },
    },
    MuiPaper: {
      root: {
        color: '#595d6e',
        boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
      },
      elevation2: {
        boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
      },
      rounded: {
        // uses rounded by default, no need for that
        // if there is a demand for the Paper being rounded, write a custom selector for that
        borderRadius: 'unset',
      },
    },
    MuiTableFooter: {
      root: {
        border: 'unset',
      },
    },
    MuiInputLabel: {
      shrink: {
        whiteSpace: 'nowrap',
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        display: 'block',
        padding: '8px 16px 16px',
      },
    },
    MuiToolbar: {
      gutters: {
        padding: '0px 16px',
        '@media (min-width: 600px)': {
          padding: '0px 16px',
        },
      },
    },
  },
});

export default theme;
