import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '#utils/styles';

import { ColoredCircleIcon } from '../../ui-helper';

const useStyles = makeStyles(styles);

const SpacecraftDatabaseStatusCard = ({ isLoaded }) => {
  const classes = useStyles();

  return (
    <Card className={classes.Card}>
      <CardHeader title="Spacecraft Database" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
      <CardContent className={classes.CardContent}>
        <Table style={{ width: '100%' }} size="small">
          <TableBody>
            <TableRow>
              <TableCell style={{ width: '300px' }}>
                <b>Spacecraft Database Loaded</b>
              </TableCell>
              <TableCell>
                <ColoredCircleIcon status={isLoaded ? 'success' : 'danger'} /> {`${isLoaded ? 'Yes' : 'No'}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

SpacecraftDatabaseStatusCard.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
};

export default SpacecraftDatabaseStatusCard;
