import PropTypes from 'prop-types';
import React from 'react';

import C3SLogoImageURL from '../../../../assets/images/c3s-logo-brand-horizontal.svg';

const Wordmark = ({ height, ...props }) => (
  <img
    alt="Logo"
    src={C3SLogoImageURL}
    {...props}
    style={{ height: height ?? '42px', width: 'auto', margin: '16px' }}
  />
);

Wordmark.propTypes = {
  height: PropTypes.oneOfType(PropTypes.number, PropTypes.string).isRequired,
};

export default Wordmark;
