module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-why-did-you-render/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/bamboo-agent/xml-data/build-dir/CPGSS-MOCUIF226-JOB2/src/components/Layout.jsx"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
