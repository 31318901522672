import { List, Paper } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import ListItemLink from '#components/ui-helper/ListItemLink';
import useMissionSelect from '#hooks/useMissionSelect';

import NavigationBox from '../NavigationBox';
import { useStyles } from '../styles';
import { DebugAndTestingNavigation, TestConnectorNavigation } from './testing-navigation';

const spaceSegmentNav = [
  {
    title: 'Satellites',
    items: [
      { text: 'Satellite Management', path: `satellites/satellite-management` },
      { text: 'Satellite Control', path: `satellites/satellite-control` },
    ],
  },
  {
    title: 'Protocols',
    items: [{ text: 'TCTM', path: `protocols/tctm` }],
  },
  {
    title: 'Services',
    items: [
      { text: 'File Server', path: `services/file-server` },
      { text: 'Message Logging', path: `services/message-logging` },
      {
        text: 'Spacecraft Database',
        path: `services/spacecraft-database`,
      },
    ],
  },
];

const groundSegmentNav = [
  {
    items: [{ text: 'EGSE Control', path: 'egse' }],
  },
  {
    title: 'Tracking Stations',
    items: [
      { text: 'Station Catalog', path: 'tracking-stations/station-catalog' },
      { text: 'Station Management', path: 'tracking-stations/station-management' },
      { text: 'Station Clusters', path: 'tracking-stations/station-clusters' },
    ],
  },
];

export const NavigationMissionBox = () => {
  const { missionOptions } = useMissionSelect();

  const missionsNav = useMemo(
    () => [
      {
        items: missionOptions.map(({ value, label }) => ({ text: label.toUpperCase(), path: value })),
      },
    ],
    [missionOptions],
  );

  return <NavigationBox title="Missions" pathPrefix="/missions" sections={missionsNav} defaultExpanded />;
};

const MainMissionNavigation = ({ currentMission }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.navGroup}>
        <Paper>
          <List dense>
            <ListItemLink to="/" icon={<ArrowBackIcon />} primary="Back to Home" />
          </List>
        </Paper>
      </div>
      <div className={classes.navGroup}>
        <NavigationBox
          title="Space Segment"
          pathPrefix={`/missions/${currentMission}/space-segment`}
          sections={spaceSegmentNav}
        />
      </div>
      <div className={classes.navGroup}>
        <NavigationBox
          title="Ground Segment"
          pathPrefix={`/missions/${currentMission}/ground-segment`}
          sections={groundSegmentNav}
        />
      </div>
      <div className={classes.navGroup}>
        <DebugAndTestingNavigation currentMission={currentMission} />
      </div>
    </>
  );
};

MainMissionNavigation.propTypes = {
  currentMission: PropTypes.string.isRequired,
};

export const MissionNavigation = ({ currentMission }) => {
  return (
    <Router>
      <MainMissionNavigation currentMission={currentMission} default />
      <TestConnectorNavigation path="debug-and-testing/test-connectors/:currentTestConnector/*" />
    </Router>
  );
};

MissionNavigation.propTypes = {
  currentMission: PropTypes.string.isRequired,
};
