import { ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const ListItemLink = ({ icon, avatar, primary, secondary, secondaryAction, to }) => {
  const renderLink = useMemo(() => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />), [
    to,
  ]);

  return (
    <li>
      <ListItem button component={renderLink}>
        {
          // eslint-disable-next-line no-nested-ternary
          icon ? <ListItemIcon>{icon}</ListItemIcon> : avatar ? <ListItemAvatar>{avatar}</ListItemAvatar> : null
        }
        <ListItemText primary={primary} secondary={secondary} />
        {secondaryAction ? <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction> : null}
      </ListItem>
    </li>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  avatar: PropTypes.element,
  secondaryAction: PropTypes.element,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  to: PropTypes.string.isRequired,
};

ListItemLink.defaultProps = {
  icon: undefined,
  avatar: undefined,
  secondary: undefined,
  secondaryAction: undefined,
};

export default ListItemLink;
