/* eslint react/jsx-filename-extension: "off", import/prefer-default-export: "off" */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const onRedirectCallback = (appState) => navigate(appState?.returnTo || '/');

export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENTID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.GATSBY_AUTH0_AUDIENCE}
    scope={process.env.GATSBY_AUTH0_SCOPE}
    useRefreshTokens
  >
    {element}
  </Auth0Provider>
);

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
};
