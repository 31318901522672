import { createMuiTheme, Drawer, makeStyles, ThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import TestingContextProvider from '#services/testing/TestingContextProvider';
import TrackingContextProvider from '#services/tracking-station/TrackingContextProvider';

// import BottomDrawer from './page-fragments/BottomDrawer';
import Navigation from './page-fragments/navigation/Navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: '#1A1C26',
    colorScheme: 'dark',
    width: 240,
    padding: theme.spacing(1),
  },
  pageContent: {
    flexGrow: 1,
    padding: '8px 16px 16px 16px',
  },
}));

const navTheme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      paper: '#222533',
    },
  },
});

const ProtectedLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <TrackingContextProvider>
      <TestingContextProvider>
        <div className={classes.root}>
          <ThemeProvider theme={navTheme}>
            <Drawer
              variant="permanent"
              className={classes.drawer}
              classes={{ paper: classes.drawerPaper }}
              anchor="left"
              open
            >
              <Navigation />
            </Drawer>
          </ThemeProvider>
          <main className={classes.pageContent}>{children}</main>
        </div>
      </TestingContextProvider>
    </TrackingContextProvider>
  );
};

ProtectedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedLayout;
