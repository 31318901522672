import { List, Paper } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import ListItemLink from '#components/ui-helper/ListItemLink';
import TestingContext from '#contexts/TestingContext';
import useCurrentMissionStructMeta from '#hooks/useCurrentMissionStructMeta';
import useSpacecraftDatabase from '#hooks/useSpacecraftDatabase';

import NavigationBox from '../NavigationBox';
import NavigationItems from '../NavigationItems';
import { useStyles } from '../styles';

const StructNameFromMeta = ({ structId }) => {
  const structMeta = useCurrentMissionStructMeta(structId);

  return structMeta?.displayName ?? null;
};

export const TestConnectorNavigation = ({ currentMission, currentTestConnector }) => {
  const classes = useStyles();

  const {
    spacecraftDatabase: { missions },
  } = useSpacecraftDatabase();
  const currentMissionMeta = missions[currentMission];

  const { testConnectors } = useContext(TestingContext);
  const currentTestConnectorMeta = testConnectors[currentTestConnector];

  const protocol = currentTestConnectorMeta?.commandProtocolStack?.[0] ?? currentMissionMeta?.commandingProtocol;

  let testConnectorNav = [];
  switch (currentTestConnectorMeta?.connectorFeatureSet) {
    case 'bus-tester':
    case 'access-port':
    case 'rf-link':
      testConnectorNav = [
        {
          items: [{ text: 'Configuration', path: 'configuration' }],
        },
        {
          title: 'Protocols',
          items: [{ text: 'TCTM', path: 'protocols/tctm' }],
        },
      ];
      break;
    case 'owl-tester':
      testConnectorNav = [
        {
          title: 'Protocols',
          items: [{ text: 'PC2GS', path: 'protocols/pc2gs' }],
        },
      ];
      break;
    case null:
    case undefined:
      testConnectorNav = [
        {
          items: [{ text: 'Setup', path: 'setup' }],
        },
        {
          title: 'Protocols',
          items: [{ text: <StructNameFromMeta structId={protocol} />, path: `protocols/${protocol}` }],
        },
      ];
      break;
    default:
      break;
  }

  return (
    <>
      <div className={classes.navGroup}>
        <Paper>
          <List dense>
            <ListItemLink
              to={`/missions/${currentMission}/debug-and-testing`}
              icon={<ArrowBackIcon />}
              primary="Back to Testing"
            />
          </List>
        </Paper>
      </div>
      <div className={classes.navGroup}>
        <Paper>
          <NavigationItems
            pathPrefix={`/missions/${currentMission}/debug-and-testing/test-connectors/${currentTestConnector}`}
            sections={testConnectorNav}
          />
        </Paper>
      </div>
    </>
  );
};

TestConnectorNavigation.propTypes = {
  currentMission: PropTypes.string.isRequired,
  currentTestConnector: PropTypes.string.isRequired,
};

const debugAndTestingNav = [
  {
    title: 'Services',
    items: [
      { text: 'File Server', path: `services/file-server` },
      { text: 'Message Logging', path: `services/message-logging` },
      { text: 'Automated Testing', path: `services/automated-test` },
    ],
  },
];

export const DebugAndTestingNavigation = ({ currentMission }) => {
  const { testConnectors } = useContext(TestingContext);

  const sections = useMemo(
    () => [
      {
        items: [{ text: 'Test Connector Catalog', path: 'test-connectors' }],
      },
      {
        title: 'Test Connectors',
        items: Object.entries(testConnectors).map(([key, { displayName }]) => ({
          text: displayName,
          path: `test-connectors/${key}`,
        })),
      },
      ...debugAndTestingNav,
    ],
    [testConnectors],
  );

  return (
    <NavigationBox title="Debug & Testing" pathPrefix={`/missions/${currentMission}/debug-and-testing`}>
      <NavigationItems pathPrefix={`/missions/${currentMission}/debug-and-testing`} sections={sections} />
    </NavigationBox>
  );
};

DebugAndTestingNavigation.propTypes = {
  currentMission: PropTypes.string.isRequired,
};
